import { endOfToday, startOfToday } from 'date-fns'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { refetchQueries } from '../../../../common/constants/refetchQueries'
import { ApolloDataType, EmployeeType } from '../../../../common/prop-types'
import notify from '../../../../common/services/notification'
import { fixDate } from '../../../../common/utils/calendar'
import EmployeeProfileInfoView from './EmployeeProfileInfoView'

class EmployeeProfileInfo extends Component {
  state = {
    showHolidayIndicator: true,
  }

  assignJobtitle = async change => {
    try {
      const variables = {
        id: change.id,
        employeeId: this.props.employee.id,
      }
      await this.props.AssignJobTitle({
        variables,
        refetchQueries,
      })
      return { success: true }
    } catch (error) {
      return { success: false, error }
    }
  }

  handleValueChange = async change => {
    const { success } = await this.assignJobtitle(change.jobTitle.value)

    if (success) {
      notify('success', 'Notification.Employee.Update.Success')
    } else {
      notify('error', 'Notification.Employee.Update.Failed')
    }
  }

  handleEditModeChange = ({ editing }) => {
    this.setState({ showHolidayIndicator: !editing })
  }

  render() {
    const {
      CurrentCompany: { currentCompany: { jobTitles = [] } = {} },
      employee,
      isAdmin,
    } = this.props

    const jobTitle = {
      value: employee.jobTitle,
      label: employee.jobTitle && employee.jobTitle.name,
    }

    const jobs = jobTitles.map(j => ({
      value: j,
      label: j.name,
    }))

    const onHolidayVariables = {
      filter: {
        statuses: ['APPROVED'],
        intervalStart: fixDate(startOfToday()),
        intervalEnd: fixDate(endOfToday()),
        employeeIds: [employee.id],
        types: ['VACATION'],
      },
    }

    const props = {
      onValueChange: this.handleValueChange,
      jobTitles: jobs,
      jobTitle,
      employee,
      onHolidayVariables,
      isAdmin,
      showHolidayIndicator: this.state.showHolidayIndicator,
      onEditModeChange: this.handleEditModeChange,
    }

    return <EmployeeProfileInfoView {...props} />
  }
}

EmployeeProfileInfo.propTypes = {
  CurrentCompany: ApolloDataType.isRequired,
  employee: EmployeeType.isRequired,
  AssignJobTitle: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
}

export default EmployeeProfileInfo
