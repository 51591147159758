import {
  shape,
  instanceOf,
  arrayOf,
  string,
  func,
  object,
  bool,
  oneOf,
  number,
} from 'prop-types'

const SexType = oneOf(['', 'MALE', 'FEMALE'])
export const HolidayRequestTypeType = oneOf(['VACATION', 'SICK_LEAVE'])

const HolidayRequestStatusType = oneOf([
  'APPROVED',
  'PLANNED',
  'REJECTED',
  'REQUESTED',
])
const PermissionType = shape({
  id: string.isRequired,
  name: string.isRequired,
})
const PermissionListType = arrayOf(PermissionType)

const RoleType = shape({
  id: string.isRequired,
  name: string.isRequired,
})

export const RoleListType = arrayOf(RoleType)

export const JobTitleType = shape({
  id: string.isRequired,
  name: string.isRequired,
})

export const JobTitleListType = arrayOf(JobTitleType)

const TeamType = shape({
  id: string.isRequired,
  name: string.isRequired,
})

const TeamListType = arrayOf(TeamType)

export const DateType = instanceOf(Date)
export const DateListType = arrayOf(DateType)

export const ChildType = shape({
  id: string.isRequired,
  sex: SexType,
  firstName: string.isRequired,
  lastName: string,
  birthDate: DateType.isRequired,
  disability: bool,
})

export const ChildListType = arrayOf(ChildType)

export const HolidayRequestType = shape({
  id: string.isRequired,
  startDate: DateType.isRequired,
  endDate: DateType.isRequired,
  status: HolidayRequestStatusType.isRequired,
  statusChangeDate: DateType,
  description: string,
  employee: object.isRequired,
  type: HolidayRequestTypeType.isRequired,
})

export const HolidayRequestListType = arrayOf(HolidayRequestType)

const HolidaysBreakdownType = shape({
  id: string.isRequired,
  year: number.isRequired,
  employeeId: number.isRequired,
  availableHolidaysCounter: number.isRequired,
  unaccountedHolidaysCounter: number.isRequired,
  baseHolidaysCounter: number.isRequired,
  childrenHolidaysCounter: number.isRequired,
  ageHolidaysCounter: number.isRequired,
  birthsHolidaysCounter: number.isRequired,
  assignedHolidaysCounter: number.isRequired,
  extraHolidaysCounter: number.isRequired,
  sickLeavesCounter: number.isRequired,
  plannedHolidaysCounter: number.isRequired,
  takenHolidaysInThePast: number,
  takenHolidaysInTheFuture: number,
})

const HolidaysBreakdownListType = arrayOf(HolidaysBreakdownType)

export const EmployeeType = shape({
  id: string.isRequired,
  hireDate: string,
  firstName: string.isRequired,
  lastName: string.isRequired,
  avatarURL: string,
  sex: SexType,
  holidayRquests: HolidayRequestListType,
  jobTitle: JobTitleType,
  birthDate: string,
  active: bool.isRequired,
  permissions: PermissionListType,
  role: RoleType,
  children: ChildListType,
  holidaysBreakdowns: HolidaysBreakdownListType,
})

export const CompanyHolidayType = shape({
  id: string.isRequired,
  date: DateType.isRequired,
})
export const CompanyHolidayListType = arrayOf(CompanyHolidayType)

export const EmployeeListType = arrayOf(EmployeeType)

export const CompanyType = shape({
  id: string.isRequired,
  name: string.isRequired,
  email: string,
  jobTitles: JobTitleListType,
  holidayRquests: HolidayRequestListType,
  employees: EmployeeListType.isRequired,
  companyHolidays: CompanyHolidayListType,
  teams: TeamListType,
  roles: RoleListType.isRequired,
})

export const DateSelectorDataType = shape({
  prev: string.isRequired,
  next: string.isRequired,
  current: string.isRequired,
})

export const FunctionType = func
export const RenderFunction = FunctionType

export const LocaleType = shape({ code: string.isRequired })
export const LocaleListType = arrayOf(LocaleType)
export const HistoryType = instanceOf(Object)
export const GqlClientType = object
export const GqlDataType = shape({
  data: instanceOf(Object),
})

export const UserTitleType = shape({
  value: string,
  label: string,
})

export const MeType = shape({
  data: EmployeeType,
  isAuthenticated: bool.isRequired,
})

export const CompanyRegistrationType = shape({
  companyName: string.isRequired,
})
export const VerificationFormType = shape({
  confirmationCode: string.isRequired,
})

export const UserRegistrationFormType = shape({
  email: string.isRequired,
  firstName: string.isRequired,
  lastName: string.isRequired,
  password: string.isRequired,
})

export const ApolloDataType = shape({
  error: instanceOf(Object),
  loading: bool,
})

export const AddHolidayViewConfigType = shape({
  title: string.isRequired,
  showEmployeeSelector: bool.isRequired,
  showDaysLeft: bool.isRequired,
  type: oneOf(['VACATION', 'SICK_LEAVE', 'COMPANY_HOLIDAY']).isRequired,
})

export const HolidayRequestStatusListType = arrayOf(
  oneOf(['APPROVED', 'PLANNED', 'REJECTED', 'REQUESTED', 'SICK_LEAVE'])
)

export const IntlType = shape({ formatMessage: func.isRequired })

export const OptionType = shape({
  key: string.isRequired,
  value: string.isRequired,
})

export const OptionTypeList = arrayOf(OptionType)

export const SortByType = shape({
  field: string,
  order: oneOf(['ASC', 'DESC', '']),
})

export const TabType = shape({
  id: string.isRequired,
  title: string.isRequired,
})
export const TabListType = arrayOf(TabType)

export const SvgType = shape({})
