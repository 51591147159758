import { getYear } from 'date-fns'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { EmployeeType } from '../../../../common/prop-types'
import { calculateHolidayCountersForYear } from '../../../../common/utils/calculations'
import { selectSelectedDate } from '../../../../redux/profile/profile.selector'
import {
  Container,
  CounterContainer,
  CounterNumber,
  CounterTitle,
} from './styles'

export default function EmployeeTimeOffHeader({ employee }) {
  const selectedDate = useSelector(selectSelectedDate)

  const counters = calculateHolidayCountersForYear(
    employee,
    getYear(selectedDate)
  )

  return (
    <Container>
      <Counter
        number={counters.availableHolidays}
        messageId="EmployeeProfile.Header.HolidaysAvailble" // fennmarado szabadnap
        textColor="orangeLight"
        backgroundColor="orangeVeryPale"
      />
      <Counter
        number={counters.allowedHolidays}
        messageId="EmployeeProfile.Header.HolidaysAllowance" // osszes szabadnap
      />
      <Counter
        number={counters.takenHolidaysInThePast}
        messageId="EmployeeProfile.Header.HolidaysTakenInThePast" // kivett szabadnap
      />
      <Counter
        number={counters.takenHolidaysInTheFuture}
        messageId="EmployeeProfile.Header.HolidaysTakenInTheFuture" // jövőben kivett szabadnap
      />
      <Counter
        number={counters.plannedHolidays}
        messageId="EmployeeProfile.Header.HolidaysPlanned" // tervezett szabadnap
      />
      <Counter
        number={counters.sickLeaves}
        messageId="EmployeeProfile.Header.SickLeavesTaken" // betegszabadnap
        textColor="gray"
        backgroundColor="white"
      />
    </Container>
  )
}

EmployeeTimeOffHeader.propTypes = {
  employee: EmployeeType.isRequired,
}

function Counter({
  number,
  messageId,
  textColor = 'grayDark',
  backgroundColor = 'grayVeryLight',
}) {
  return (
    <CounterContainer color={backgroundColor}>
      <CounterNumber color={textColor}>{number}</CounterNumber>
      <CounterTitle color={textColor}>
        <FormattedMessage id={messageId} />
      </CounterTitle>
    </CounterContainer>
  )
}

Counter.propTypes = {
  number: PropTypes.number.isRequired,
  messageId: PropTypes.string.isRequired,
  textColor: PropTypes.oneOf(['orangeLight', 'grayDark', 'gray']),
  backgroundColor: PropTypes.oneOf([
    'orangeVeryPale',
    'grayVeryLight',
    'white',
  ]),
}
