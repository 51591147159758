import { endOfMonth, startOfMonth } from 'date-fns'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import { fixDate } from '../../../../common/utils/calendar'
import { selectSelectedDate } from '../../../../redux/profile/profile.selector'
import EmployeeHolidaysModuleView from './EmployeeHolidaysModuleView'

export default function EmployeeHolidaysModule({ employeeId }) {
  const selectedDate = useSelector(selectSelectedDate)

  const queryVariables = {
    filter: {
      employeeIds: [employeeId],
      intervalStart: fixDate(startOfMonth(selectedDate)),
      intervalEnd: fixDate(endOfMonth(selectedDate)),
    },
    orders: [{ orderBy: 'START_DATE', order: 'ASC' }],
  }

  return <EmployeeHolidaysModuleView queryVariables={queryVariables} />
}

EmployeeHolidaysModule.propTypes = {
  employeeId: PropTypes.string.isRequired,
}
