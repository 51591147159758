import styled from 'styled-components/macro'
import { Header } from '../../../../../elements'

export const EmployeeTimeOffContainer = styled(Header)`
  @media ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
  }
  padding: 12px 0;
`

export const Container = styled.div`
  padding: 0 28px;
  @media ${({ theme }) => theme.media.mobile} {
    padding: 0 10px;
  }
`
