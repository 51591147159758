import styled from 'styled-components/macro'
import { Text } from '../../../../components/styles/Text'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 16px;
  @media ${({ theme }) => theme.media.mediumDesktop} {
    gap: 8px;
  }
`

export const CounterContainer = styled.div`
  width: 120px;
  height: 92px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  border-radius: 4px;
  padding: 16px 4px 4px;
  background: ${({ theme, color }) => theme.colors[color]};
`

export const CounterNumber = styled(Text)`
  color: ${({ theme, color }) => theme.colors[color]};
  font-size: 36px;
  font-weight: 600;
`

export const CounterTitle = styled(Text)`
  color: ${({ theme, color }) => theme.colors[color]};
  text-align: center;
  font-size: 12px;
  line-height: 1.2;
`
