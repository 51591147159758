import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EmployeeType,
  HolidayRequestStatusListType,
  HolidayRequestTypeType,
  JobTitleType,
} from '../../../../common/prop-types'
import { cutText } from '../../../../common/utils/text'
import HolidayIndicator from '../../../../components/HolidayIndicator'
import Input from '../../../../components/Input'
import ProfileImage from '../../../../components/ProfileImage'
import { H1, Text } from '../../../../components/styles'
import { Spacer } from '../../../../components/styles/Spacer'
import WithQuery from '../../../../components/WithQuery'
import { QueryActiveRequests } from '../../../../graphql/queries/holidayRequests'
import EmployeeNameEditor from '../EmployeeNameEditor'
import {
  Container,
  EmployeeProfileInfoContainer,
  ImageContainer,
} from './styles'

const HolidayIndicatorWithQuery = WithQuery(HolidayIndicator)

export default function EmployeeProfileInfoView({
  employee,
  jobTitle,
  jobTitles,
  onValueChange,
  onHolidayVariables,
  onEditModeChange,
  showHolidayIndicator,
  isAdmin,
}) {
  return (
    <EmployeeProfileInfoContainer>
      <ImageContainer>
        <ProfileImage src={employee.avatarUrl} size="large" />
      </ImageContainer>
      <Spacer display="flex" flexDirection="column">
        <Container>
          {isAdmin ? (
            <EmployeeNameEditor
              employee={employee}
              onEditModeChange={onEditModeChange}
            />
          ) : (
            <H1>{cutText(`${employee.lastName} ${employee.firstName}`, 19)}</H1>
          )}

          {showHolidayIndicator && (
            <HolidayIndicatorWithQuery
              query={QueryActiveRequests}
              variables={onHolidayVariables}
              hiddenWhenLoading
            />
          )}
        </Container>
        <Spacer>
          {isAdmin ? (
            <Input
              type="selector"
              id="title"
              label="Input.Position.Placeholder"
              hideLabel
              options={jobTitles}
              value={jobTitle.value ? jobTitle : null}
              onChange={position => onValueChange({ jobTitle: position })}
              isSearchable={false}
              withBorder={false}
              selectorStyle="userProfile"
              hideErrors
            />
          ) : (
            <Spacer pt={10}>
              <Text fontSize={20}>
                {employee.jobTitle ? (
                  cutText(employee.jobTitle.name, 25)
                ) : (
                  <FormattedMessage id="Employee.Position.NoPosition" />
                )}
              </Text>
            </Spacer>
          )}
        </Spacer>
      </Spacer>
    </EmployeeProfileInfoContainer>
  )
}

EmployeeProfileInfoView.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  jobTitles: PropTypes.arrayOf(
    PropTypes.shape({
      value: JobTitleType,
      label: PropTypes.string,
    })
  ),
  jobTitle: PropTypes.shape({
    id: JobTitleType,
    name: PropTypes.string,
    value: JobTitleType,
  }),
  onHolidayVariables: PropTypes.shape({
    filter: PropTypes.shape({
      statuses: HolidayRequestStatusListType.isRequired,
      intervalStart: PropTypes.string.isRequired,
      intervalEnd: PropTypes.string.isRequired,
      employeeIds: PropTypes.arrayOf(PropTypes.string.isRequired),
      types: PropTypes.arrayOf(HolidayRequestTypeType.isRequired),
    }).isRequired,
  }).isRequired,
  onValueChange: PropTypes.func.isRequired,
  employee: EmployeeType.isRequired,
  showHolidayIndicator: PropTypes.bool,
  onEditModeChange: PropTypes.func,
}
