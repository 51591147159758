import gql from 'graphql-tag'
import { holidayRequestFragments } from './holidayRequests'

export const employeeFragments = {
  base: gql`
    fragment BaseEmployeeFields on Employee {
      active
      avatarURL
      birthDate
      email
      firstName
      hireDate
      id
      lastName
      selectedLang
      sex
      isGoogleConnected
    }
  `,
  children: gql`
    fragment ChildrenFields on Employee {
      children {
        birthDate
        disability
        firstName
        id
        sex
      }
    }
  `,
  jobTitle: gql`
    fragment JobTitleFields on Employee {
      jobTitle {
        id
        name
      }
    }
  `,
  role: gql`
    fragment RoleFields on Employee {
      role {
        id
        name
      }
    }
  `,
  teams: gql`
    fragment TeamsFields on Employee {
      teams {
        id
        name
      }
    }
  `,
  holidaysBreakdowns: gql`
    fragment HolidaysBreakdownFields on Employee {
      holidaysBreakdowns {
        id
        year
        employeeId
        availableHolidaysCounter
        unaccountedHolidaysCounter
        baseHolidaysCounter
        childrenHolidaysCounter
        ageHolidaysCounter
        birthsHolidaysCounter
        assignedHolidaysCounter
        extraHolidaysCounter
        sickLeavesCounter
        plannedHolidaysCounter
        takenHolidaysInThePast
        takenHolidaysInTheFuture
      }
    }
  `,
}

export const QueryEmployeeProfile = gql`
  query employeeProfile($filter: EmployeesFilter) {
    employees(filter: $filter) {
      ...BaseEmployeeFields
      ...ChildrenFields
      ...TeamsFields
      ...JobTitleFields
      ...RoleFields
      ...HolidaysBreakdownFields
      holidayRequests {
        ...HolidayRequestFields
      }
    }
  }
  ${employeeFragments.base}
  ${employeeFragments.holidaysBreakdowns}
  ${employeeFragments.children}
  ${employeeFragments.jobTitle}
  ${employeeFragments.teams}
  ${employeeFragments.role}
  ${holidayRequestFragments.base}
`

export const QueryCompanyCalendar = gql`
  query companyCalendar(
    $employeesFilter: EmployeesFilter
    $employeeOrders: [EmployeesOrder!] = []
    $holidayRequestsFilter: HolidayRequestsFilter
  ) {
    employees(filter: $employeesFilter, orders: $employeeOrders) {
      ...BaseEmployeeFields
      holidayRequests(filter: $holidayRequestsFilter) {
        id
        startDate
        endDate
        status
        description
        type
        deletable
        approvable
        rejectable
        editable
      }
    }
  }
  ${employeeFragments.base}
`

export const QueryActiveEmployees = gql`
  query activeEmployees(
    $filter: EmployeesFilter
    $orders: [EmployeesOrder!] = []
  ) {
    employees(filter: $filter, orders: $orders) {
      ...BaseEmployeeFields
      ...RoleFields
      ...JobTitleFields
    }
  }
  ${employeeFragments.base}
  ${employeeFragments.role}
  ${employeeFragments.jobTitle}
`

export const QueryEmployeesForReport = gql`
  query employeesForReport(
    $filter: EmployeesFilter = {}
    $orders: [EmployeesOrder!] = []
  ) {
    employees(filter: $filter, orders: $orders) {
      ...BaseEmployeeFields
      ...JobTitleFields
      ...RoleFields
    }
  }
  ${employeeFragments.base}
  ${employeeFragments.jobTitle}
  ${employeeFragments.role}
`

export const QueryInactiveEmployees = gql`
  query inactiveEmployees(
    $filter: EmployeesFilter
    $orders: [EmployeesOrder!] = []
  ) {
    employees(filter: $filter, orders: $orders) {
      ...BaseEmployeeFields
      ...RoleFields
      ...JobTitleFields
    }
  }
  ${employeeFragments.base}
  ${employeeFragments.role}
  ${employeeFragments.jobTitle}
`
